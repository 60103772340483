<template>
    <div class="w-100">
        <div class="p-3">
            <h1 style="opacity: 0.7; font-size: 1.25rem;" class="generic-typography">{{ $gettext("Skills") }}</h1>
        </div>
        <div>
            <!-- <b-form class="customized-form w-100">
                <ValidationObserver ref="edit_candidate_skills" tag="div">

                    <b-row class="p-1 px-3">
                        <div class="col-lg-6 col-12">
                            <ValidationProvider name="user-skills" v-slot="{ errors, classes }">
                                <div class="custom-placeholder">
                                    Select Skills
                                </div>
                                <v-select id="user-skills" clearable multiple placeholder="" class="form-control"
                                    :class="classes" v-model="skills" :options="skillOption">
                                </v-select>
                                <div class="error-msg" :class="classes">
                                    <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                            <div class="d-flex flex-wrap align-items-start m-2 px-3 w-100">
                                <span v-for="(s, i) in skills" class="vs__selected" :key="i">
                                    {{ s }}
                                    <img style="cursor: pointer;" @click="removeSkills(i)" class="ml-2"
                                        src="@/assets/zaajira-candidate/assets/icons/cross.svg" alt="" />
                                </span>
                            </div>
                        </div>
                    </b-row>

                    <div class="col-12 my-4 btn-flex-fd-row-jc-sb">
                        <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">Save Change</b-button>
                    </div>
                </ValidationObserver>
            </b-form> -->

            <b-form class="customized-form w-100">
                <ValidationObserver ref="edit_candidate_skills" tag="div">
                    <div>
                        <b-row class="p-1 px-3">
                            <div class="col-lg-6 col-12">
                                <ValidationProvider rules="required|Skill" name="user-skills" v-slot="{ errors, classes }">
                                    <div class="custom-placeholder">
                                        {{ search ? '' : $gettext("Select_Skills") }}
                                    </div>
                                    <v-select id="user-skills" clearable multiple placeholder="" class="form-control"
                                        :class="classes" v-model="skills" @search="searchSkills"
                                        :get-option-label="(option) => option.skill_name"
                                        :options="skillSearchOption.length ? skillSearchOption : skillOption">
                                    </v-select>
                                    <div class="error-msg" :class="classes">
                                        <span>{{ errors[0] }}</span>
                                        <!-- <span>{{ errors[0] ? 'Skill must be min 3 and max 10' : errors[0] }}</span> -->
                                    </div>
                                </ValidationProvider>
                            </div>

                        </b-row>
                        <div class="d-flex flex-wrap align-items-start m-2 px-3 zc-wm100-wlg50">
                            <span v-for="(skill, i) in skills" class="vs__selected" :key="i">
                                {{ skill.skill_name }}
                                <img style="cursor: pointer;" @click="removeSkills(i)" class="ml-2"
                                    src="@/assets/zaajira-candidate/assets/icons/cross.svg" alt="" />
                            </span>
                        </div>

                        <div class="col-12 my-4 mt-3 btn-flex-fd-row-jc-sb">
                            <!-- <span @click="goBack" class="go-back">Back</span> -->
                            <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">{{
                                $gettext("Save_change") }}</b-button>
                        </div>

                        <!-- <div class="col-12 mb-4">
                                <b-button @click.prevent.stop="ValidateInput" class="btn full-width-btn" variant="primary"
                                    size="sm">Next</b-button>
                            </div> -->
                    </div>
                </ValidationObserver>
            </b-form>
        </div>
    </div>
</template>

<script>
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import { throttle } from "lodash";

export default {
    name: "Support",
    data() {
        return {
            skills: null,
            skillOption: [],
            searchDelay: 1000,
            search: null,
            skillSearchOption: [],
        }
    },
    beforeMount() {
        this.PreFilledData()
    },
    methods: {
        goBack() {
            this.$router.push({ path: 'industry' })
        },
        removeSkills(index) {
            console.log(index);
            this.skills.splice(index, 1)
        },
        // ValidateInput() {
        //     return this.$refs.edit_candidate_skills.validate().then((result) => {
        //         if (result) {
        //             this.$router.push({ path: 'education-details' })
        //         }
        //     })
        // },
        removeDuplicatesSkills(data) {
            const uniqueSkills = {};
            const result = [];

            // Iterate through the types array
            data.forEach(type => {
                // Check if the skill_id is unique
                if (!uniqueSkills[type.skill_id]) {
                    uniqueSkills[type.skill_id] = true;
                    result.push(type);
                }
            });

            return result;
        },
        async getAllIndustrySkills() {
            try {
                const userData = this.$store.getters.getLoggedUser
                const payload = {
                    user_id: userData.userId,
                    industries: userData.userIndustry
                }
                console.log('get all Skills payload', payload);
                const SkillsIndustry = await MasterServices.GetAllSkillsIndustry(payload)
                console.log("SkillsIndustry", SkillsIndustry.data);
                return SkillsIndustry.data.data;
            } catch (error) {

            }
        },
        debounce(func, delay) {
            let timeoutId;

            return function (...args) {
                clearTimeout(timeoutId);

                timeoutId = setTimeout(() => {
                    func(...args);
                }, delay);
            };
        },
        // searchSkills(searchTxt) {
        //     console.log('searchTxt', searchTxt);
        //     const debouncedFunction = this.debounce(async function () {
        //         console.log("Debounced function called");
        //         try {
        //             // const res = await MasterServices.GetSearchSkills(searchTxt)
        //             // console.log('res', res);
        //             return searchTxt
        //         } catch (error) {
        //             console.log(error);
        //         }

        //     }, 2000);
        //     const txt = debouncedFunction()
        //     console.log("txt", txt);
        // },


        // searchSkills(searchTxt) {
        //     // console.log(searchTxt);
        //     // const searchSkillDebounce = this.debounce(async function (searchTxt) {
        //     //     console.log('searchTxt', searchTxt);

        //     //     try {
        //     //         const res = await MasterServices.GetSearchSkills(searchTxt);
        //     //         console.log('res', res);
        //     //         this.skillOption = res.data.data.types;

        //     //     } catch (error) {
        //     //         console.error(error);
        //     //     }
        //     // }, 2000)
        //     // searchSkillDebounce()

        //     let timeoutId

        //     clearTimeout(timeoutId);

        //     timeoutId = setTimeout(() => {
        //         console.log('searchTXT', searchTxt);
        //     }, 2000);
        // },
        searchSkills(searchTxt, loading) {
            this.search = searchTxt
            const delay = this.searchDelay
            // clearTimeout(delay);
            // console.log("search", searchTxt);
            setTimeout(() => {
                if (searchTxt.length > 3) {
                    loading(true)
                    MasterServices.GetSearchSkills(searchTxt)
                        .then(async (res) => {
                            // console.log("res", res);
                            if (this.search) {
                                this.skillOption = this.removeDuplicatesSkills(res.data.data.types)
                                loading(false)
                            }
                            else {
                                this.skillOption = await this.getAllIndustrySkills()
                                loading(false)
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            loading(false)
                        })
                }
            }, delay);
        },

        async GetMasterData() {
            this.skillOption = await this.getAllIndustrySkills()
            console.log('this.skillOption', this.skillOption);

        },
        async PreFilledData() {
            let loader = this.$loading.show();
            await this.GetMasterData()
            const userData = this.$store.getters.getLoggedUser
            console.log("userData.userId", userData.userId);
            CandidateService.GetSelectedSkills(userData.userId)
                .then((res) => {
                    console.log("GetselectedIndustry", res.data.data.types);
                    this.skills = this.removeDuplicatesSkills(res.data.data.types)
                    loader.hide()
                })
                .catch((error) => {
                    console.log("error", error);
                    loader.hide()
                })

        },
        ValidateInput() {
            return this.$refs.edit_candidate_skills.validate().then(async (result) => {
                if (result) {
                    console.log(result);
                    const skill_id = this.skills.map((s) => s.skill_id)
                    let skill_idString = skill_id.join(',')

                    const payload = {
                        job_type_ids: skill_idString

                    }
                    console.log('payload', payload);
                    let loader = this.$loading.show();
                    CandidateService.updateUser(payload)
                        .then((res) => {
                            // console.log(res);
                            // console.log('res', res.data.data);
                            // console.log('res', res.data.message);
                            this.$toasted.show(`${res.data.message}`, {
                                duration: 6000,
                                type: "success",
                                icon: "check",
                            });
                            const storePayload = {
                                userSkills: skill_id
                            }
                            this.$store.dispatch("onSetSelectedSkills", storePayload);

                            // this.$router.push({ path: 'education-details' })
                            loader.hide();
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
            })
        }
    },
}
</script>

<style></style>